<!--
  -  Copyright (C) Healabs 2022 - All Rights Reserved
  -  Unauthorized copying of this file, via any medium is strictly prohibited
  -  Proprietary and confidential
  -
  -->

<template>
  <v-container fluid class="h-100 pa-0" :class="$vuetify.theme.dark ? '' : 'white'">
    <v-row no-gutters class="fill-height" justify="center" align="center">
      <v-col cols="12" lg="6" class="fill-height d-flex align-center justify-center flex-column select-view elevation-x" @click="goToEntries">
        <v-img :src="require('@/assets/img/illustrations/reminder-informations.svg')" max-height="400px" width="450px" contain />
        <div>
          <v-list-item-title class="text-h3 primary--text font-alt text-center">Admissions</v-list-item-title>
          <v-list-item-subtitle class="text-center text-h6  font-weight-light o-60 mb-11">Gérer ma liste d'admission</v-list-item-subtitle>
        </div>
      </v-col>

      <v-col cols="12" lg="6" class="fill-height d-flex align-center justify-center flex-column select-view elevation-x" @click="goToSurveys">
        <v-img :src="require('@/assets/img/illustrations/reminder-documents.svg')" max-height="400px" width="450px" contain />
        <div>
          <v-list-item-title class="text-h3 primary--text font-alt text-center">Questionnaires</v-list-item-title>
          <v-list-item-subtitle class="text-center text-h6  font-weight-light o-60 mb-11">Gérer les questionnaires</v-list-item-subtitle>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>

  export default {
    name: 'Dashboard',
    methods: {
      goToEntries() {
        this.$router.push('/admissions/admissions-pending')
      },
      goToSurveys() {
        this.$router.push('/surveys/surveys-pending')
      }
    }
  }
</script>
<style lang="scss" scoped>
  .select-view {
    cursor: pointer;
    transform: scale(0.96);
    filter: grayscale(100%);
    padding: 10px 20px;
    opacity: .6;
    transition: all .3s;
    &:hover {
      transform: scale(1);
      filter: grayscale(0);
      opacity: 1;
    }
  }
</style>
